<template lang="pug">
v-main
  v-main
    v-container
      .d-flex.flex-column.justify-space-between.align-center.mt-5
        v-card(width="400")
          v-card-text
            v-img(src="@/assets/howto.png" max-height="400" max-width="400" centered)
    v-btn(:href="url"  fixed, dark, fab, bottom, right, color="green" link target="_blank")
      v-icon {{ icons.mdiHelpBox }}
</template>

<script>
import { mdiArrowLeft, mdiHelpBox  } from '@mdi/js'
export default {
  name: 'Help',
  data: () => ({
    icons: { mdiArrowLeft, mdiHelpBox  },
    url:
      'https://docs.google.com/presentation/u/0/d/18WMJCnP4pvD2JaNg2XeDAIwc_HiuyBMr5p3EnHd5JQQ/',
  }),
}
</script>
